/* eslint-disable no-param-reassign */
import _set from 'lodash.set';
import _get from 'lodash.get';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ConfigState, NodeAddress } from './config.types';
import { ChannelOption, FontSource, TypographyCase } from './global-config.types';
import { GenericConfig, MixedSection, Section } from './page-config.types';
import { v4 as uuid } from 'uuid';

export * from './config.types';

const defaultSearchForm = {
  channel: ChannelOption.DefaultSales,
  minPrice: true,
  maxPrice: true,
  minBeds: true,
  maxBeds: false,
  items: [],
  backgroundOpacity: 1,
  color: 'primary',
};

const initialState: ConfigState = {
  globalConfig: {
    _id: null,
    agencyId: null,
    domain: '',
    mainNav: { variant: '' },
    searchForm: defaultSearchForm,
    externalScripts: {
      analytics: {
        gtmCustomEventNames: {},
        items: [],
      },
      standalone: {
        items: [],
      },
    },
    propertyCard: { variant: '' },
    sectionPadding: 4,
    generalSettings: {
      enableGoogleStaticMaps: false,
      disableHomeflowCookieConsent: false,
    },
    typography: {
      fonts: [],
      body: {
        font: { name: 'Nunito Sans', source: FontSource.Google },
        case: TypographyCase.Normal,
        bold: false,
        italic: false,
        underline: false,
        size: 1,
      },
      h1: {
        font: { name: 'Nunito Sans', source: FontSource.Google },
        case: TypographyCase.Normal,
        bold: true,
        italic: false,
        underline: false,
        size: 2,
      },
      h2: {
        font: { name: 'Nunito Sans', source: FontSource.Google },
        case: TypographyCase.Normal,
        bold: true,
        italic: false,
        underline: false,
        size: 2,
      },
      h3: {
        font: { name: 'Nunito Sans', source: FontSource.Google },
        case: TypographyCase.Normal,
        bold: true,
        italic: false,
        underline: false,
        size: 2,
      },
      h4: {
        font: { name: 'Nunito Sans', source: FontSource.Google },
        case: TypographyCase.Normal,
        bold: true,
        italic: false,
        underline: false,
        size: 2,
      },
      h5: {
        font: { name: 'Nunito Sans', source: FontSource.Google },
        case: TypographyCase.Normal,
        bold: true,
        italic: false,
        underline: false,
        size: 2,
      },
      h6: {
        font: { name: 'Nunito Sans', source: FontSource.Google },
        case: TypographyCase.Normal,
        bold: true,
        italic: false,
        underline: false,
        size: 2,
      },
      nav: {
        font: { name: 'Nunito Sans', source: FontSource.Google },
        case: TypographyCase.Normal,
        bold: true,
        italic: false,
        underline: false,
        size: 2,
      },
    },
    footer: {
      footerType: 'default',
      sections: [],
    },
    urls: {},
  },
  campaignsConfig: {
    agencyId: null,
    campaigns: {},
  },
  pageConfig: {
    _id: null,
    agencyId: null,
    page: null,
    metadata: {},
    areas: {},
    createdAt: '',
    updatedAt: '',
  },
  themeSettings: {
    _id: null,
    agencyId: 0,
    theme: '',
    settings: {},
  },
  selection: null,
  selectedFooterSection: null,
  selectedFooterItem: null,
  selectedFooterElement: null,
  selectedSearchForm: 'default',
  freezeReorder: false,
  previewMode: false,
  unsavedChanges: false,
  sectionSchemas: {},
  sitePath: null,
};

function findSection(config: ConfigState, sectionAddress: NodeAddress | null) {
  if (!sectionAddress) return null;
  const { areaName, sectionId } = sectionAddress;
  const area = config.pageConfig.areas[areaName];
  if (!area) return null;

  return area.find((section) => section.id === sectionId);
}

function findItem(config: ConfigState, itemAddress: NodeAddress | null) {
  if (!itemAddress) return null;
  const section = findSection(config, itemAddress);
  if (!section) return null;

  const { itemIdChain } = itemAddress;

  if (!itemIdChain) return null;

  return itemIdChain.reduce((parentItem, itemId) => {
    const items = parentItem.items as GenericConfig[];
    return items.find((searchedItem) => itemId === searchedItem.id) as GenericConfig;
  }, section.configuration as GenericConfig);
}

function findElement(config: ConfigState, elementAddress: NodeAddress | null) {
  if (!elementAddress) return null;
  const { elementId } = elementAddress;
  if (!elementId) return null;

  const item = findItem(config, elementAddress);
  if (item) {
    return item.elements.find((element) => element.id === elementId);
  }

  return findSection(config, elementAddress)?.configuration.elements.find(
    (element) => element.id === elementId
  );
}

function findNode(config: ConfigState, nodeAddress: NodeAddress | null) {
  if (!nodeAddress) return null;
  if (nodeAddress.elementId) return findElement(config, nodeAddress);
  if (nodeAddress.itemIdChain) return findItem(config, nodeAddress);
  return findSection(config, nodeAddress)?.configuration;
}

export const configSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setGlobalConfig(state, action) {
      state.globalConfig = action.payload;
    },
    setGlobalConfigId(state, action) {
      // eslint-disable-next-line no-underscore-dangle
      state.globalConfig._id = action.payload;
    },
    setGlobalConfigTypography(state, action) {
      state.globalConfig.typography = action.payload;
      state.unsavedChanges = true;
    },
    setGlobalConfigField(state, action) {
      const { field, data } = action.payload;

      _set(state.globalConfig, field, data);
      state.unsavedChanges = true;
    },
    setThemeSettingsConfig(state, action) {
      state.themeSettings = action.payload;
    },
    setThemeSettingsConfigField(state, action) {
      const { field, data } = action.payload;

      _set(state.themeSettings, field, data);
      state.unsavedChanges = true;
    },
    setPageConfig(state, action) {
      state.pageConfig = action.payload;
    },
    setPageConfigId(state, action) {
      // eslint-disable-next-line no-underscore-dangle
      state.pageConfig._id = action.payload;
    },
    setAreaSections(state, action) {
      const { areaName, sections } = action.payload;
      state.pageConfig.areas[areaName] = sections;
      state.unsavedChanges = true;
    },

    toggleSharedWith(state, action) {
      const newSharedWith = state.pageConfig.sharedWith ? [...state.pageConfig.sharedWith] : [];

      if (newSharedWith.includes(action.payload)) {
        const index = newSharedWith.indexOf(action.payload);

        newSharedWith.splice(index, 1);
      } else {
        newSharedWith.push(action.payload);
      }

      state.unsavedChanges = true;
      state.pageConfig.sharedWith = newSharedWith;
    },

    /* This works off the selected item if it exists otherwise the selected section. Element selection is ignored for this */
    setSelectedField(state, action) {
      if (!state.selection) return;

      const { field, data } = action.payload;

      const { elementId, ...itemAddress } = state.selection.address;

      const node = findNode(state, itemAddress);

      if (node) {
        _set(node, field, data);
        state.unsavedChanges = true;
      }
    },

    setField(state, action) {
      const { nodeAddress, field, data } = action.payload;
      const node = findNode(state, nodeAddress);

      if (node) {
        _set(node, field, data);
        state.unsavedChanges = true;
      }
    },

    setSectionName(state, action) {
      const { sectionAddress, name } = action.payload;
      const section = findSection(state, sectionAddress);
      if (!section) return;

      section.name = name;
    },
    setSelectedFooterSection(state, action) {
      state.selectedFooterSection = action.payload;
    },
    setSelectedFooterItem(state, action) {
      state.selectedFooterItem = action.payload;
    },
    setSelection(state, action) {
      state.selection = action.payload;
    },
    setSelectedFooterElement(state, action) {
      state.selectedFooterElement = action.payload;
    },
    setFreezeReorder(state, action) {
      state.freezeReorder = action.payload;
    },
    setPreviewMode(state, action) {
      state.previewMode = action.payload;
    },
    setPageMetadataField(state, action) {
      state.pageConfig.metadata[action.payload.field as keyof typeof state.pageConfig.metadata] =
        action.payload.data;
      state.unsavedChanges = action.payload;
    },
    setTheme(state, action) {
      state.globalConfig.theme = action.payload;
      state.unsavedChanges = true;
    },
    setPageField(state, action) {
      const { field, data } = action.payload;
      _set(state.pageConfig, field, data);
      state.unsavedChanges = true;
    },
    setUnsavedChanges(state, action) {
      state.unsavedChanges = action.payload;
    },
    setSectionSchemas(state, action) {
      state.sectionSchemas = action.payload;
    },
    setFooterItems(state, action) {
      const { sectionId, data } = action.payload;
      const sectionIndex = state.globalConfig.footer.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex === -1) return;

      _set(state.globalConfig.footer.sections[sectionIndex], 'items', data);
      state.unsavedChanges = true;
    },
    setFooterElements(state, action) {
      const { sectionId, itemId, data } = action.payload;
      const { sections } = state.globalConfig.footer;

      const sectionIndex = sections.findIndex((section) => section.id === sectionId);
      if (sectionIndex === -1) return;

      const itemIndex = sections[sectionIndex].items.findIndex((item) => item.id === itemId);
      if (itemIndex === -1) return;

      _set(state.globalConfig.footer.sections[sectionIndex], `items[${itemIndex}].elements`, data);
      state.unsavedChanges = true;
    },
    setFooterSections(state, action) {
      state.globalConfig.footer.sections = action.payload;
      state.unsavedChanges = true;
    },
    setSitePath(state, action) {
      state.sitePath = action.payload;
    },
    addCustomSearchForm(state, action) {
      state.globalConfig.searchForm.customSearchForms = {
        ...state.globalConfig.searchForm.customSearchForms,
        [action.payload]: defaultSearchForm,
      };
      state.unsavedChanges = true;
    },
    deleteCustomSearchForm(state, action) {
      const {
        [action.payload as keyof typeof state.globalConfig.searchForm.customSearchForms]: f,
        ...rest
      } = state.globalConfig.searchForm.customSearchForms;

      state.globalConfig.searchForm.customSearchForms = rest;
      state.unsavedChanges = true;
    },
    setSelectedSearchForm(state, action) {
      state.selectedSearchForm = action.payload;
    },

    setOverrideField(state, action) {
      const { agencyId, field, data } = action.payload;

      const sectionOverrides = state.pageConfig.sectionOverrides || {};

      // if the agency has no overrides, create an empty object
      if (!sectionOverrides[agencyId]) sectionOverrides[agencyId] = {};

      if (!state.selection?.address) return;

      // get the ID of the selected section to use as the key for the override
      const { sectionId } = state.selection.address;

      const parentSection = findSection(state, state.selection?.address || null);

      const newSection =
        sectionOverrides[agencyId][sectionId] ||
        structuredClone(JSON.parse(JSON.stringify(parentSection)));

      // if this section is not currently overridden, add it to overrides
      if (!sectionOverrides[agencyId][sectionId])
        sectionOverrides[agencyId][sectionId] = newSection;

      _set(newSection.configuration, field, data);

      state.unsavedChanges = true;
      state.pageConfig.sectionOverrides = sectionOverrides;
    },

    setSectionOverride(state, action) {
      const { agencyId } = state.globalConfig;
      const { section } = action.payload;

      if (!state.pageConfig.sectionOverrides) state.pageConfig.sectionOverrides = {};

      state.pageConfig.sectionOverrides[agencyId as number][section.id] = JSON.parse(
        JSON.stringify(section)
      );

      state.unsavedChanges = true;
    },

    removeSectionOverride(state, action) {
      const { agencyId } = state.globalConfig;
      const newSectionOverrides = { ...state.pageConfig.sectionOverrides };
      delete newSectionOverrides[agencyId as number][action.payload];
      state.unsavedChanges = true;
      state.pageConfig.sectionOverrides = newSectionOverrides;
    },

    setGlobalConfigVariable(state, action) {
      const { name, value } = action.payload;

      if (!state.globalConfig.customVariables) state.globalConfig.customVariables = {};

      state.unsavedChanges = true;
      state.globalConfig.customVariables[name] = value;
    },

    setGlobalConfigVariables(state, action) {
      state.unsavedChanges = true;
      state.globalConfig.customVariables = action.payload;
    },

    setCampaignsConfig(state, action) {
      state.campaignsConfig = action.payload;
    },

    setCampaign(state, action) {
      const { key } = action.payload;

      state.campaignsConfig.campaigns[key] = action.payload;
      state.unsavedChanges = true;
    },

    newCampaign(state) {
      const key = uuid();

      state.campaignsConfig.campaigns[key] = {
        name: 'Enter name',
        start: {},
        end: {},
        key,
      };
    },
  },
});

export const {
  setGlobalConfig,
  setGlobalConfigId,
  setGlobalConfigTypography,
  setGlobalConfigField,
  setThemeSettingsConfig,
  setThemeSettingsConfigField,
  setPageConfig,
  setPageConfigId,
  setAreaSections,
  setField,
  setSelectedField,
  setOverrideField,
  setSectionName,
  setSelection,
  setSelectedFooterSection,
  setSelectedFooterItem,
  setSelectedFooterElement,
  setFreezeReorder,
  setPreviewMode,
  setPageMetadataField,
  setTheme,
  setPageField,
  setUnsavedChanges,
  setSectionSchemas,
  setFooterItems,
  setFooterElements,
  setFooterSections,
  setSitePath,
  addCustomSearchForm,
  deleteCustomSearchForm,
  setSelectedSearchForm,
  toggleSharedWith,
  setGlobalConfigVariable,
  setGlobalConfigVariables,
  removeSectionOverride,
  setSectionOverride,
  setCampaignsConfig,
  setCampaign,
  newCampaign,
} = configSlice.actions;

export const selectAreaSection =
  (areaName: string, sectionId: string) => (state: { config: ConfigState }) => {
    const { agencyId } = state.config.globalConfig;
    const override = state.config.pageConfig.sectionOverrides?.[agencyId as number]?.[sectionId];

    // if it's an override, return that instead of the main section
    if (override) return override;

    return state.config.pageConfig.areas[areaName]?.find(
      (section: Section<MixedSection>) => section.id === sectionId
    );
  };

export const selectSelectedOverrideField = (field: string) => (state: { config: ConfigState }) => {
  if (!state.config.selection) return null;

  const { sectionId } = state.config.selection.address;

  const { agencyId } = state.config.globalConfig;
  const overrideSection =
    state.config.pageConfig.sectionOverrides?.[agencyId as number]?.[sectionId];

  if (!overrideSection) return null;

  return _get(overrideSection.configuration, field);
};

/** Returns the override for the currently selected section if it exists */
export const selectSelectedOverrideSection = (state: { config: ConfigState }) => {
  if (!state.config.selection) return null;

  const { sectionId } = state.config.selection.address;

  const { agencyId } = state.config.globalConfig;
  const overrideSection =
    state.config.pageConfig.sectionOverrides?.[agencyId as number]?.[sectionId];

  if (!overrideSection) return null;

  return overrideSection;
};

/** Returns a boolean for whether a section HAS any overrides */
export const selectHasOverride = (state: { config: ConfigState }) => {
  if (!state.config.selection) return false;

  const { sectionId } = state.config.selection.address;

  const overrides = state.config.pageConfig.sectionOverrides;

  if (!overrides) return false;

  let overridden = false;

  Object.keys(overrides).forEach((agencyId) => {
    if (Object.keys(overrides[+agencyId]).includes(sectionId)) overridden = true;
  });

  return overridden;
};

export const selectGlobalConfig = (state: { config: ConfigState }) => state.config.globalConfig;

export const selectGlobalConfigTypography = (state: { config: ConfigState }) =>
  state.config.globalConfig.typography;

export const selectGlobalConfigField = (field: string) => (state: { config: ConfigState }) =>
  _get(state.config.globalConfig, field);

export const selectThemeSettingsConfig = (state: { config: ConfigState }) =>
  state.config.themeSettings;

export const selectThemeSettingsConfigField = (field: string) => (state: { config: ConfigState }) =>
  _get(state.config.themeSettings, field);

export const selectPageConfig = (state: { config: ConfigState }) => state.config.pageConfig;

/** Returns the ID of the agency that owns the page */
export const selectPageAgencyId = (state: { config: ConfigState }) =>
  state.config.pageConfig.agencyId;

export const selectAreaSections = (areaName: string) => (state: { config: ConfigState }) =>
  state.config.pageConfig.areas[areaName];

export const selectFreezeReorder = (state: { config: ConfigState }) => state.config.freezeReorder;

export const selectPreviewMode = (state: { config: ConfigState }) => state.config.previewMode;

export const selectElements =
  (areaName: string, sectionId: string, itemIdChain?: string[]) =>
  (state: { config: ConfigState }) => {
    const foundSection = state.config.pageConfig.areas[areaName]?.find(
      (section: Section<MixedSection>) => section.id === sectionId
    );

    if (!foundSection) return [];

    if (!itemIdChain) {
      return foundSection.configuration.elements;
    }

    const containingItem = itemIdChain.reduce(
      (item, id: string) => item.items?.find((subItem) => subItem.id === id) as GenericConfig,
      foundSection.configuration as MixedSection
    );
    return containingItem.elements;
  };

export const selectSelectedAreaName = (state: { config: ConfigState }) =>
  state.config.selection?.address.areaName;

const selectSelection = (state: { config: ConfigState }) => state.config.selection;

const selectSection = (state: { config: ConfigState }) =>
  findSection(state.config, state.config.selection?.address || null);

export const selectSelectedSection = createSelector(
  selectSelection,
  selectSection,
  (selection, section) => {
    if (!selection) return null;
    if (!section) return null; // This can occur if a selection is deleted while being selected

    return {
      address: selection.address,
      settings: selection.settings,
      component: selection.sectionComponent,
      section,
    };
  }
);

export const selectSelectedFooterSection = (state: { config: ConfigState }) =>
  state.config.selectedFooterSection;

export const selectSelectedItem = (state: { config: ConfigState }) =>
  findItem(state.config, state.config.selection?.address || null);

export const selectSelectedFooterItem = (state: { config: ConfigState }) =>
  state.config.selectedFooterItem;

export const selectSelectedSearchForm = (state: { config: ConfigState }) =>
  state.config.selectedSearchForm;

export const selectSelectedElement = (state: { config: ConfigState }) =>
  findElement(state.config, state.config.selection?.address || null);

export const selectSelectedFooterElement = (state: { config: ConfigState }) =>
  state.config.selectedFooterElement;

/* This works based on selected section/item not selected element */
export const selectSelectedField = (field: string) => (state: { config: ConfigState }) => {
  if (!state.config.selection) return null;

  const { elementId, ...itemAddress } = state.config.selection.address;
  const node = findNode(state.config, itemAddress);

  if (node) return _get(node, field);

  return null;
};

export const selectField =
  (nodeAddress: NodeAddress, field: string) => (state: { config: ConfigState }) => {
    const node = findNode(state.config, nodeAddress);
    if (node) return _get(node, field);

    return null;
  };

export const selectSelectedSectionField = (field: string) => (state: { config: ConfigState }) => {
  const section = findSection(state.config, state.config.selection?.address || null);
  if (section) return _get(section.configuration, field);

  return null;
};

export const selectPageMetadata = (state: { config: ConfigState }) =>
  state.config.pageConfig.metadata;

export const selectTheme = (state: { config: ConfigState }) => state.config.globalConfig.theme;

export const selectPageField = (field: string) => (state: { config: ConfigState }) =>
  _get(state.config.pageConfig, field);

export const selectUnsavedChanges = (state: { config: ConfigState }) => state.config.unsavedChanges;

export const selectSectionSchemas = (state: { config: ConfigState }) => state.config.sectionSchemas;

export const selectFooterSections = (state: { config: ConfigState }) =>
  state.config.globalConfig.footer.sections;

export const selectSelectionAddress = (state: { config: ConfigState }) =>
  state.config.selection?.address;

export const selectSelectionSettings = (state: { config: ConfigState }) =>
  state.config.selection?.settings;

export const selectSitePath = (state: { config: ConfigState }) => state.config.sitePath;

export const selectSharedWith = (state: { config: ConfigState }) =>
  state.config.pageConfig.sharedWith;

export const selectGlobalConfigVariables = (state: { config: ConfigState }) =>
  state.config.globalConfig.customVariables;

export const selectCampaignsConfig = (state: { config: ConfigState }) =>
  state.config.campaignsConfig;

export default configSlice.reducer;
